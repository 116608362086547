<template>
  <UiTourDriver
    ref="driver"
    :tour="TOURS_IDS.NEWLY_ADDED"
    :highlight
    :type="TOURS_TYPES.UPDATE"
    @destroyed="handleDestroy"
  />
</template>

<script setup lang="ts">
import type { DriveStep } from 'driver.js'
import type { UiTourDriver } from '#build/components'
import { TOURS_IDS, TOURS_TYPES } from '~/constants'

const driver = ref<InstanceType<typeof UiTourDriver> | null>(null)

onMounted(() => {
  useMittListen('tour:newlyAdded', () => {
    if (driver.value?.driverObj) {
      driver.value.startTour()
    }
  })
})

const highlight = ref<DriveStep>({
  element: '#lead_1',
  popover: {
    title: 'Newly added leads',
    description: `Now you can easily find leads, that are recently added to your pipeline.`,
    nextBtnText: "Okay, I'll check!",
    showButtons: ['next'],
    onNextClick: () => {
      if (driver.value?.driverObj) {
        driver.value.driverObj.destroy()
      }
    },
  },
})

const handleDestroy = () => {
  useMittEvent('tour:newlyAdded')
  console.log('event destroyed')
}

onUnmounted(() => {
  useMittRemove('tour:newlyAdded')
})
</script>

<style scoped lang="scss"></style>
